import { Box, Flex, Link, type LinkProps, Text } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { type customTheme } from '@innerwell/chakra/theme';
import { type Route } from 'next';
import NextLink from 'next/link';

import { useChat } from '@/contexts/chat-context';

import { Icon, type IconName } from './Icon';
import type { NavIconMeta } from '../utils/navItems';

interface IProps<THref extends string> extends LinkProps {
   link: Route<THref>;
   icon: IconName;
   label: string;
   active?: boolean;
   meta?: NavIconMeta;
}
export function NavItemLink<T extends string>({
   link,
   icon: iconName,
   label,
   active,
   meta,
   ...linkProps
}: IProps<T>) {
   const { chatStatus } = useChat();
   const theme = useTheme() as typeof customTheme;

   return (
      <NextLink href={link} passHref legacyBehavior>
         <Link
            display="flex"
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems="center"
            py={{ base: 2, lg: '16px' }}
            px={{ base: 1, lg: 4 }}
            bg={active ? 'buttonGhost.600' : 'none'}
            borderRadius={{ lg: '28px' }}
            color="white"
            textAlign={{ base: 'center', lg: 'start' }}
            textDecor="none"
            _hover={{
               textDecoration: 'none',
               bg: 'buttonGhost.600',
            }}
            {...linkProps}
         >
            <Box pos="relative">
               <Icon
                  name={iconName}
                  w="24px"
                  h="24px"
                  fill="white"
                  mr={{ base: 0, lg: 3 }}
               />
               {Boolean(meta?.isChat && chatStatus?.unreadMessagesCount) && (
                  <Flex
                     boxSize={{ base: '17px', lg: '20px' }}
                     borderRadius="full"
                     textAlign="center"
                     alignItems="center"
                     justifyContent="center"
                     ml={2}
                     color={theme.colors.chat.text.secondary}
                     backgroundColor={theme.colors.chat.background.quaternary}
                     fontSize="12px"
                     top={-1}
                     right={-2}
                     pos="absolute"
                     display={{ base: 'flex', lg: 'none' }}
                  >
                     {chatStatus?.unreadMessagesCount}
                  </Flex>
               )}
            </Box>
            <Text
               as="span"
               lineHeight={1.1}
               mt={{ base: 1, lg: 0 }}
               fontWeight={600}
               fontSize={{ base: '11px', lg: '18px' }}
            >
               {label}
            </Text>
            {Boolean(meta?.isChat && chatStatus?.unreadMessagesCount) && (
               <Flex
                  boxSize={{ base: '17px', lg: '20px' }}
                  borderRadius="full"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  display={{ base: 'none', lg: 'flex' }}
                  ml={2}
                  color={theme.colors.chat.text.secondary}
                  backgroundColor={theme.colors.chat.background.quaternary}
                  fontSize="12px"
               >
                  {chatStatus?.unreadMessagesCount}
               </Flex>
            )}
         </Link>
      </NextLink>
   );
}
